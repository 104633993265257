<template>
  <div class="warp">
    <div class="wktime">
      <div v-for="item in list" :key="item" class="listRid">
        <div class="listRidtitle">{{ item.Title }}</div>
        <div class="listRidname">
          <span>联系人: {{ item.Linkmen }}</span>
          <span>联系电话: {{ item.Linkway }}</span>
        </div>
        <div class="listRidname">
          <span>物业名称: {{ item.Property }}</span>
        </div>
        <div class="listRidname">
          <span>物业联系人: {{ item.PropertyLead }}</span>
        </div>
        <div class="listRidname">
          <span>物业联系电话: {{ item.PropertyTel }}</span>
        </div>
        <div class="listRidname">地址: {{ item.Address }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { WeGetRegionPage } from "@/api/index";

export default {
  data() {
    return {
      RegionFrom: {}, //小区列表传参
      list: [], //小区列表
    };
  },
  created() {
    this.RegionPage();
  },
  methods: {
    onClickLeft() {
      window.history.go(-1);
    },
    // 获取小区列表
    RegionPage() {
      WeGetRegionPage(this.RegionFrom).then((res) => {
        console.log(res);
        this.list = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}
.listRid {
  background: rgb(238, 236, 236);
  border-radius: 15px;
  box-shadow: 1px 1px 1px #cececc;
  margin: 8px 0px;
  padding: 8px;
  font-size: 14px;
}
.listRid .listRidtitle {
  border-bottom: 1px solid #999;
  padding-bottom: 8px;
  font-size: 16px;
}
.listRidname {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.listRidAddress {
  padding: 3px 0px;
}
</style>